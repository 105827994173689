<template>
  <div>
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="basic/district/page">
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('user.name','名称')">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('user.type','上级名称')">
            <el-input v-model.trim="filter.parentName" type="userType"></el-input>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="code" label="地区编号" align="center"></el-table-column>
      <el-table-column prop="name" :label="$l('user.name','地区名称')" align="center"></el-table-column>
      <el-table-column prop="extPath" label="完整城市路径" align="center"></el-table-column>
      <el-table-column prop="parentCode" label="上级编号" align="center"></el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        filter: {
          phone: "",
          name: "",
          type: "",
          status: "",
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },

      toggleStatus(row) {
        let statusDesc = row.status === "enabled" ? "禁用" : "启用";
        this.$confirm(`确定${statusDesc + row.phone}吗?`, "提示", {type: "warning"}).then(() => {
          this.$http.put(`users/${row.id}/toggleStatus`).then(() => {
            this.getList(-1);
          });
        });
      },
      resetPassword(row) {
        this.$prompt("请输入新密码", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          inputPattern: this.$rule.password.pattern,
          inputErrorMessage: "6~20位,不能为纯数字或者纯字母",
        }).then(({value}) => {
          this.$http.put(`users/${row.id}/resetPassword?newPassword=${value}`).then(() => {
            this.$message.success("重置密码成功");
          });
        });
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.phone}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete("users", row.id).then(() => {
            this.getList(-1);
            this.$message.success(row.phone + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
